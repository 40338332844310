import React from 'react';

interface LoadingScreenImageProps {
    letter: string;
    index: number;
}

const LoadingScreenImage: React.FC<LoadingScreenImageProps> = ({ letter, index }) => {
    return (
        <img
            src={require(`../assests/letters/${letter}.png`)}
            alt={letter}
            className="bouncing-letter"
            style={{ animationDelay: `${index * 0.1}s` }}
        />
    );
};

export default LoadingScreenImage;