// src/pages/Home.tsx

import React from 'react';
import LoadingScreenImage  from '../components/LoadingScreenImage';   

const Home: React.FC = () => {
    return (

        <div className="home-container">
            <div className='home-title'>
                <h1>Welcome to Yolo Game Studios Internal Site</h1>
                <p>Select an option from the navigation menu.</p>
            </div>
            <div className="home-company-name">
                {['Y', 'O1', 'L', 'O2'].map((letter, index) => (
                    <LoadingScreenImage key={index} letter={letter} index={index} />
                ))}
            </div>
        </div>
           
    );
};

export default Home;