//issueview.tsx
import React, { useState, useEffect, useRef } from 'react';
import Ticket from '../models/Ticket';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { IoClose, IoCalendar } from "react-icons/io5";
import axios from 'axios';

interface IssueViewProps {
    tickets: Ticket[];
    filters: {
        game: string[];
        platform: string[];
        ticketType: string[];
        version: string[];
        excludeTags: string[];
    };
}

const IssueView: React.FC<IssueViewProps> = ({ tickets, filters }) => {
    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [termCounts, setTermCounts] = useState<{ [key: string]: { customRange1: number; customRange2: number; customRange3: number, tickets: { [key: string]: Ticket[] } } }>({});
    const [totalCounts, setTotalCounts] = useState<{ customRange1: number; customRange2: number; customRange3: number }>({ customRange1: 0, customRange2: 0, customRange3: 0 });
    const [popupData, setPopupData] = useState<{ term: string; period: string; tickets: Ticket[] } | null>(null);
    const [showDatePickers, setShowDatePickers] = useState<boolean>(false);
    const [translations, setTranslations] = useState<{ [term: string]: { es: string, fr: string, de: string, id: string, it: string, ja: string, pl: string, pt: string, ru: string, tr: string, ko: string } }>({});
    const popupRef = useRef<HTMLDivElement>(null);

    const ticketDates = tickets.map(ticket => new Date(ticket.createdAt));
    const minDate = new Date(Math.min(...ticketDates.map(date => date.getTime())));
    const maxDate = new Date(Math.max(...ticketDates.map(date => date.getTime())));

    const [range1, setRange1] = useState<[Date, Date]>([
        new Date(new Date().setDate(new Date().getDate() - 1)), 
        new Date(new Date().setDate(new Date().getDate() - 1))
    ]);
    const [range2, setRange2] = useState<[Date, Date]>([
        new Date(new Date().setDate(new Date().getDate() - 7)), 
        new Date(new Date().setDate(new Date().getDate() - 1))
    ]);
    const [range3, setRange3] = useState<[Date, Date]>([minDate, maxDate]);

    const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            const newTerm = inputValue.trim();
            if (!searchTerms.includes(newTerm)) {
                try {
                    const response = await axios.get(`https://app-462nclm3ma-uc.a.run.app/translate?word=${newTerm}`);
                    console.log('Translation API Response:', response.data);
                    const { en, nl, fr, de, id, it, ja, pl, pt, ru, es, tr, ko } = response.data;
                    setTranslations(prev => ({ ...prev, [newTerm]: { en, nl, fr, de, id, it, ja, pl, pt, ru, es, tr, ko } }));
                    setSearchTerms(prevTerms => [...prevTerms, newTerm]);
                } catch (error) {
                    console.error('Translation API error:', error);
                }
            }
            setInputValue('');
        }
    };

    const removeSearchTerm = (term: string) => {
        setSearchTerms(searchTerms.filter(t => t !== term));
        setTermCounts(prevCounts => {
            const updatedCounts = { ...prevCounts };
            delete updatedCounts[term];
            return updatedCounts;
        });
    };

    const matchesFilter = (ticket: Ticket) => {
        return (Object.keys(filters) as Array<keyof typeof filters>).every((key) => {
            const filterValues = filters[key];
    
            // Skip excludeTags here and handle separately
            if (key === 'excludeTags') return true;
    
            return filterValues.length === 0 || filterValues.some((filter) => ticket.tags.includes(filter));
        }) && 
        // Additional condition to check that the ticket does not contain any excludeTags
        (filters.excludeTags.length === 0 || !filters.excludeTags.some((excludeTag) => ticket.tags.includes(excludeTag)));
    };

    useEffect(() => {
        const calculateCounts = () => {
            const counts: { [key: string]: { customRange1: number; customRange2: number; customRange3: number; tickets: { [key: string]: Ticket[] } } } = {};
            const totalCounts = { customRange1: 0, customRange2: 0, customRange3: 0 };

            const isWithinRange = (createdAt: string, start: Date | null, end: Date | null) => {
                const createdDate = new Date(createdAt);
                if (start && end) {
                    if (start.getTime() === end.getTime()) {
                        return createdDate.toDateString() === start.toDateString();
                    }
                    return createdDate >= start && createdDate <= end;
                }
                return false;
            };

            tickets.forEach(ticket => {
                if (!matchesFilter(ticket)) return;
                if (isWithinRange(ticket.createdAt, range1[0], range1[1])) totalCounts.customRange1++;
                if (isWithinRange(ticket.createdAt, range2[0], range2[1])) totalCounts.customRange2++;
                if (isWithinRange(ticket.createdAt, range3[0], range3[1])) totalCounts.customRange3++;
            });

            searchTerms.forEach(term => {
                const termTranslations = translations[term] || { es: term, fr: term, de: term, id: term, it: term, ja: term, pl: term, pt: term, ru: term, tr: term, ko: term };
                const termTickets = { customRange1: new Set<Ticket>(), customRange2: new Set<Ticket>(), customRange3: new Set<Ticket>() };

                tickets.forEach(ticket => {
                    if (!matchesFilter(ticket)) return;

                    const termFound = [term, termTranslations.es, termTranslations.fr, termTranslations.de, termTranslations.id, termTranslations.it, termTranslations.ja, termTranslations.pl, termTranslations.pt, termTranslations.ru, termTranslations.tr, termTranslations.ko].some(translatedTerm =>
                        ticket.description.toLowerCase().includes(translatedTerm.toLowerCase()) ||
                        ticket.subject.toLowerCase().includes(translatedTerm.toLowerCase())
                    );

                    if (termFound) {
                        if (isWithinRange(ticket.createdAt, range1[0], range1[1])) termTickets.customRange1.add(ticket);
                        if (isWithinRange(ticket.createdAt, range2[0], range2[1])) termTickets.customRange2.add(ticket);
                        if (isWithinRange(ticket.createdAt, range3[0], range3[1])) termTickets.customRange3.add(ticket);
                    }
                });

                counts[term] = { 
                    customRange1: termTickets.customRange1.size,
                    customRange2: termTickets.customRange2.size,
                    customRange3: termTickets.customRange3.size,
                    tickets: {
                        customRange1: Array.from(termTickets.customRange1),
                        customRange2: Array.from(termTickets.customRange2),
                        customRange3: Array.from(termTickets.customRange3)
                    }
                };
            });

            setTermCounts(counts);
            setTotalCounts(totalCounts);
        };

        calculateCounts();
    }, [tickets, filters, searchTerms, range1, range2, range3, translations]);

    const calculatePercentage = (count: number, total: number) => {
        return total > 0 ? ((count / total) * 100).toFixed(2) : '0.00';
    };

    const handleCellClick = (term: string, period: string) => {
        setPopupData({
            term,
            period,
            tickets: termCounts[term]?.tickets[period] || []
        });
    };

    const closePopup = () => {
        setPopupData(null);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            closePopup();
        }
    };

    useEffect(() => {
        if (popupData) {
            document.addEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'hidden';
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'auto';
        }
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'auto';
        };
    }, [popupData]);

    const renderTableRows = () => {
        const rows = searchTerms.map(term => (
            <tr key={term}>
                <td style={{fontWeight:"bold"}}>{term}</td>
                <td onClick={() => handleCellClick(term, 'customRange1')} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    {termCounts[term]?.customRange1 || 0} ({calculatePercentage(termCounts[term]?.customRange1 || 0, totalCounts.customRange1)}%)
                </td>
                <td onClick={() => handleCellClick(term, 'customRange2')} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    {termCounts[term]?.customRange2 || 0} ({calculatePercentage(termCounts[term]?.customRange2 || 0, totalCounts.customRange2)}%)
                </td>
                <td onClick={() => handleCellClick(term, 'customRange3')} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    {termCounts[term]?.customRange3 || 0} ({calculatePercentage(termCounts[term]?.customRange3 || 0, totalCounts.customRange3)}%)
                </td>
            </tr>
        ));

         // Add empty rows if there are fewer than 10 search terms
         for (let i = searchTerms.length; i < 10; i++) {
            rows.push(
                <tr key={`empty-${i}`}>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }
        
        return rows;
    };

    return (
        <div className="issue-view-container">
            <h1>Issue View</h1>
            <input
                type="text"
                placeholder="Type search terms and press Enter"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleKeyPress}
                className="search-input"
            />

            <div className="search-terms-display">
                {searchTerms.map(term => (
                    <span key={term} className="search-term">
                        <a className='search-term-key'>{term}</a>
                        <button onClick={() => removeSearchTerm(term)} className="remove-term-button">
                            <IoClose />
                        </button>
                    </span>
                ))}
            </div>

            <button onClick={() => setShowDatePickers(!showDatePickers)} className="calendar-toggle-button">
                <IoCalendar size={24}/>
            </button>

            {showDatePickers && (
                <div className="date-pickers">
                    <DatePicker
                        selected={range1[0]}
                        onChange={(date: [Date | null, Date | null]) => date && setRange1([date[0] as Date, date[1] as Date])}
                        startDate={range1[0]}
                        endDate={range1[1]}
                        selectsRange
                        inline
                    />
                    <DatePicker
                        selected={range2[0]}
                        onChange={(dates: [Date | null, Date | null]) => dates && setRange2([dates[0] as Date, dates[1] as Date])}
                        startDate={range2[0]}
                        endDate={range2[1]}
                        selectsRange
                        inline
                    />
                    <DatePicker
                        selected={range3[0]}
                        onChange={(dates: [Date | null, Date | null]) => dates && setRange3([dates[0] as Date, dates[1] as Date])}
                        startDate={range3[0]}
                        endDate={range3[1]}
                        selectsRange
                        inline
                    />
                </div>
            )}

            <table className="term-count-table">
                <thead>
                    <tr>
                        <th>Search Term</th>
                        <th>
                            Starting Date: {range1[0]?.toLocaleDateString()}<br />
                            End Date: {range1[1]?.toLocaleDateString()}<br />
                            Ticket Count: {totalCounts.customRange1}<br />
                        </th>
                        <th>
                            Starting Date: {range2[0]?.toLocaleDateString()}<br />
                            End Date: {range2[1]?.toLocaleDateString()}<br />
                            Ticket Count: {totalCounts.customRange2}<br />
                        </th>
                        <th>
                            Starting Date: {range3[0]?.toLocaleDateString()}<br />
                            End Date: {range3[1]?.toLocaleDateString()}<br />
                            Ticket Count: {totalCounts.customRange3}<br />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {renderTableRows()}
                </tbody>
            </table>

            {popupData && (
                <div className="popup">
                    <div className="popup-content" ref={popupRef}>
                        <div className='popup-title'>
                            <h2>{popupData.term} - {popupData.period}</h2>
                            <button className="remove-term-button" onClick={closePopup} style={{ fontSize: "30px" }}>
                                <IoClose />
                            </button>
                        </div>
                        <div className="popup-ids"> 
                            <table className="popup-table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Days Ago</th>
                                        <th>Subject</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {popupData.tickets
                                        .map((ticket) => {
                                            const daysPassed = Math.floor((new Date().getTime() - new Date(ticket.createdAt).getTime()) / (1000 * 60 * 60 * 24));
                                            return { id: ticket.id, daysPassed, subject: ticket.subject };
                                        })
                                        .sort((a, b) => b.daysPassed - a.daysPassed)
                                        .map(({ id, daysPassed, subject }) => (
                                            <tr key={id}>
                                                <td>
                                                    <a href={tickets.find((x) => x.id === id)?.url} target="_blank" rel="noopener noreferrer">
                                                        {id.toString().length > 5 ? `See` : id}
                                                    </a>
                                                
                                                </td>
                                                <td>{daysPassed}</td>
                                                <td>{subject || 'No Subject'}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default IssueView;