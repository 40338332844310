//Ticketstatus.tsx
import React, { useEffect, useState, useRef } from 'react';
import Ticket from '../models/Ticket';
import { IoClose } from "react-icons/io5";

interface StatusCount {
    count: number;
    percentage: string;
    ids: string[];
}

interface StatusCounts {
    [key: string]: StatusCount;
}

interface TicketStatusProps {
    tickets: Ticket[];
    filters: {
        game: string[];
        platform: string[];
        ticketType: string[];
        version: string[];
        excludeTags: string[];
    };
}

const TicketStatus: React.FC<TicketStatusProps> = ({ tickets, filters }) => {
    const [statusCounts, setStatusCounts] = useState<StatusCounts>({});
    const [totalCount, setTotalCount] = useState<number>(0);
    const [popupData, setPopupData] = useState<{ status: string; ids: string[] } | null>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const getStatusCounts = () => {
        const statusCounts: StatusCounts = {};
        let totalCounts = 0;
        const fixedStatusOrder = ['new', 'open', 'pending', 'solved', 'empty_ticket'];

        tickets.forEach((ticket) => {
            const matchesFilter = (Object.keys(filters) as Array<keyof typeof filters>).every((key) => {
                const filterValues = filters[key];
        
                // Skip excludeTags here, handle separately below
                if (key === 'excludeTags') return true;
        
                return filterValues.length === 0 || filterValues.some((filter) => ticket.tags.includes(filter));
            });
        
            // Additional condition to check excludeTags: ticket must NOT contain any excludeTags
            const matchesExcludeTags = filters.excludeTags.length === 0 || !filters.excludeTags.some((excludeTag) => ticket.tags.includes(excludeTag));
        
            // Only count ticket if it matches both filters and excludeTags
            if (matchesFilter && matchesExcludeTags) {
                if (statusCounts[ticket.status]) {
                    statusCounts[ticket.status].count++;
                    statusCounts[ticket.status].ids.push(ticket.id.toString());
                } else {
                    statusCounts[ticket.status] = { count: 1, percentage: '0', ids: [ticket.id.toString()] };
                }
                totalCounts++;
            }
        });

        fixedStatusOrder.forEach((status) => {
            const count = statusCounts[status]?.count || 0;
            statusCounts[status] = { 
                count, 
                percentage: totalCounts ? ((count / totalCounts) * 100).toFixed(2) : '0.00',
                ids: statusCounts[status]?.ids || []
            };
        });

        setStatusCounts(statusCounts);
        setTotalCount(totalCounts);
    };

    useEffect(() => {
        getStatusCounts();
    }, [tickets, filters]);

    const handleStatusClick = (status: string) => {
        setPopupData({ status, ids: statusCounts[status]?.ids || [] });
    };

    const closePopup = () => {
        setPopupData(null);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            closePopup();
        }
    };

    useEffect(() => {
        if (popupData) {
            document.addEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'hidden'; // Block background scrolling
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'auto'; // Restore scrolling
        }
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'auto'; // Ensure scrolling is restored on unmount
        };
    }, [popupData]);

    const fixedStatusOrder = ['new', 'open', 'pending', 'solved', 'empty_ticket'];

    return (
        <div className='ticket-container'>
            <h1 style={{marginTop: "20px"}}>Ticket View</h1>
            <div>
                <table className="ticket-table">
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Count</th>
                            <th>Percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fixedStatusOrder.map((status) => (
                            <tr key={status}>
                                <td onClick={() => handleStatusClick(status)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                    {status}
                                </td>
                                <td>{statusCounts[status]?.count || 0}</td>
                                <td>{statusCounts[status]?.percentage || '0.00'}%</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div>
                    <strong>Total Count: {totalCount}</strong>
                </div>
            </div>
            {popupData && (
                <div className="popup">
                    <div className="popup-content" ref={popupRef}>
                        <div className='popup-title'>
                            <h2>{popupData.status} Ticket IDs</h2>
                            <button className = "remove-term-button" onClick={closePopup} style={{fontSize:"30px"}}>
                                <IoClose />
                            </button>
                        </div>
                        <div className="popup-ids"> 
                            <table className="popup-table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Days Ago</th>
                                        <th>Subject</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {popupData.ids
                                        .map((id) => {
                                            const ticket = tickets.find(ticket => ticket.id.toString() === id);
                                            const daysPassed = ticket ? Math.floor((new Date().getTime() - new Date(ticket.createdAt).getTime()) / (1000 * 60 * 60 * 24)) : 0;
                                            return {
                                                id,
                                                daysPassed,
                                                subject: ticket?.subject,
                                                ticketId: ticket?.id
                                            };
                                        })
                                        .sort((a, b) => b.daysPassed - a.daysPassed)
                                        .map(({ id, daysPassed, subject, ticketId }) => (
                                            <tr key={id}>
                                                <td>
                                                    <a href={tickets.find((x) => x.id === ticketId)?.url} target="_blank" rel="noopener noreferrer">
                                                        {id.length > 5 ? `See` : id}
                                                    </a>
                                                </td>
                                                <td>{daysPassed}</td>
                                                <td>{subject || 'No Subject'}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TicketStatus;