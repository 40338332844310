import React, { useEffect, useRef } from 'react';
import * as d3 from "d3";
import '../styles/AnimatedCountdown.css'; // Import the CSS file

interface AnimatedCountdownProps {
  timeLimit: number;
}

const AnimatedCountdown: React.FC<AnimatedCountdownProps> = ({ timeLimit }) => {
  const width = 400;
  const height = 400;
  const timePassedRef = useRef(0);
  const svgRef = useRef<SVGSVGElement | null>(null);

  const fields = [
    {
      value: timeLimit,
      size: timeLimit,
      previous: 0,
      update: function() {
        return (timePassedRef.current += 1);
      },
    },
  ];

  const nilArc = d3.arc()
    .innerRadius(width / 3 - 133)
    .outerRadius(width / 3 - 133)
    .startAngle(0)
    .endAngle(2 * Math.PI);

  const arc = d3.arc()
    .innerRadius(width / 3 - 55)
    .outerRadius(width / 3 - 25)
    .startAngle(0)
    .endAngle((d: any) => ((d.value / d.size) * 2 * Math.PI));

  useEffect(() => {
    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    const field = svg.selectAll(".field")
      .data(fields)
      .enter().append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`)
      .attr("class", "field");

    field.append("path")
      .attr("class", "path path--background")
      .attr("d", arc as unknown as string);

    const path = field.append("path")
      .attr("class", "path path--foreground");

    const label = field.append("text")
      .attr("class", "label")
      .attr("dy", ".35em");

    const update = () => {
      field.each(function(d) {
        d.previous = d.value;
        d.value = d.update();
      });

      path.transition()
        .ease(d3.easeElastic)
        .duration(500)
        .attrTween("d", arcTween);

      if (timeLimit - timePassedRef.current <= 10) {
        pulseText(label, path, field);
      } else {
        label.text(d => d.size - d.value);
      }

      if (timePassedRef.current <= timeLimit) {
        setTimeout(update, 1000 - (timePassedRef.current % 1000));
      } else {
        destroyTimer(label, path, field);
      }
    };

    update();
  }, []);

  const pulseText = (label: any, path: any, field: any) => {
    const back = field.select(".path--background");
    back.classed("pulse", true);
    label.classed("pulse", true);

    if (timeLimit - timePassedRef.current >= 0) {
      label.style("font-size", "120px")
        .attr("transform", "translate(0,4)")
        .text((d: { size: number; value: number }) => d.size - d.value);
    }

    label.transition()
      .ease(d3.easeElastic)
      .duration(900)
      .style("font-size", "90px")
      .attr("transform", "translate(0,-10)");
  };

  const destroyTimer = (label: any, path: any, field: any) => {
    label.transition()
      .ease(d3.easeBack)
      .duration(700)
      .style("opacity", "0")
      .style("font-size", "5px")
      .attr("transform", "translate(0,-40)")
      .on("end", () => {
        field.selectAll("text").remove();
      });

    path.transition()
      .ease(d3.easeBack)
      .duration(700)
      .attr("d", nilArc);

    field.select(".path--background").transition()
      .ease(d3.easeBack)
      .duration(700)
      .attr("d", nilArc)
      .on("end", () => {
        field.selectAll("path").remove();
      });
  };

  const arcTween = (b: any) => {
    const i = d3.interpolate({ value: b.previous }, b);
    return function(t: number) {
      const interpolated = arc(i(t));
      return interpolated ? interpolated : "";
    };
  };

  return <svg ref={svgRef}></svg>;
};

export default AnimatedCountdown;