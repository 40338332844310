// models/Ticket.ts
export default class Ticket {
  url: string;
  id: number;
  subject: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  tags: string[];

  constructor(data: any) {
    this.url = data.url;
    this.id = data.id;
    this.subject = data.subject;
    this.description = data.description;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.status = data.status;
    this.tags = data.tags || [];
  }
}