import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig } from 'firebase/remote-config';

// Firebase konfigürasyonları
const firebaseConfig = {
    apiKey: "AIzaSyCnnWqeeh13oltkA3zob9LvHw30EFsYTB4",
    authDomain: "internalyologamestudioscom.firebaseapp.com",
    projectId: "internalyologamestudioscom",
    storageBucket: "internalyologamestudioscom.appspot.com",
    messagingSenderId: "828428472713",
    appId: "1:828428472713:web:ac70aa1a128f9d600d5158",
    measurementId: "G-N4JKJ42HKL"
};

const firebaseConfigHiddenObjects = {
    apiKey: "AIzaSyB9kQ-AP3GVHQXm015RsU-QP6P4cMo8p-w",
    authDomain: "hiddenobjectsyolo.firebaseapp.com",
    databaseURL: "https://hiddenobjectsyolo-default-rtdb.firebaseio.com",
    projectId: "hiddenobjectsyolo",
    storageBucket: "hiddenobjectsyolo.appspot.com",
    messagingSenderId: "552563680266",
    appId: "1:552563680266:web:f6c1e66036bbe790032bf3",
    measurementId: "G-9J9LNYR51E"
};

const firebaseConfigFindMaster = {
    apiKey: "AIzaSyCfrBr6XgH7nUpcM0-LcU7TKfdmBna6Emc",
    authDomain: "findmasteryolo.firebaseapp.com",
    projectId: "findmasteryolo",
    storageBucket: "findmasteryolo.appspot.com",
    messagingSenderId: "739462267930",
    appId: "1:739462267930:web:0087bff008dba8a052fda3",
    measurementId: "G-ZBQCVZML7E"
};

const firebaseConfigFindDifferences = {
    apiKey: "AIzaSyASbUJ5UFNY7MBn-ZaJX3WSM-QGiN_a2Uw",
    authDomain: "differencesyolo.firebaseapp.com",
    databaseURL: "https://differencesyolo-default-rtdb.firebaseio.com",
    projectId: "differencesyolo",
    storageBucket: "differencesyolo.appspot.com",
    messagingSenderId: "391341937308",
    appId: "1:391341937308:web:8a638de2b1db539add1359",
    measurementId: "G-4XVW8XGL09"
};

// Firebase'i başlat
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

// Set authentication persistence to session (cleared when browser is closed)
setPersistence(auth, browserSessionPersistence).catch((error) => {
    console.error('Failed to set persistence:', error);
});

export { auth, analytics };

export const initializeFirebaseForGame = async (game: 'Find Differences' | 'Find Hidden Objects' | 'Find Master') => {
    let firebaseApp;
    switch (game) {
        case 'Find Differences':
            firebaseApp = initializeApp(firebaseConfigFindDifferences, game);
            break;
        case 'Find Hidden Objects':
            firebaseApp = initializeApp(firebaseConfigHiddenObjects, game);
            break;
        case 'Find Master':
            firebaseApp = initializeApp(firebaseConfigFindMaster, game);
            break;
        default:
            throw new Error("Unknown game selected");
    }
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000; // 1 saatlik fetch interval

    return firebaseApp;
};
