//tagusage.tsx
import React, { useEffect, useState } from 'react';
import Select, { MultiValue, ActionMeta } from 'react-select';
import Ticket from '../models/Ticket';

interface TagCount {
    tag: string;
    count: number;
    percentage: string;
}

interface TagUsageProps {
    tickets: Ticket[];
    filters: {
        game: string[];
        platform: string[];
        ticketType: string[];
        version: string[];
        excludeTags: string[];
    };
    excludedTags: string[];
    onExcludeChange: (selected: MultiValue<{ value: string; label: string }>, actionMeta: ActionMeta<{ value: string; label: string }>) => void;
}

const TagUsage: React.FC<TagUsageProps> = ({ tickets, filters, excludedTags, onExcludeChange }) => {
    const [tagCounts, setTagCounts] = useState<{ [key: string]: TagCount[] }>({});
    const [totalCounts, setTotalCounts] = useState<{ [key: string]: number }>({});
    const [includedTags, setIncludedTags] = useState<string[]>([]); // Corrected: use an empty array

    const calculateTagsForPeriod = (tagUsage: { [key: string]: { count: number; createdAt: string }[] }, days: number, totalCount: number) => {
        const now = new Date();
        const filteredTags: { [key: string]: number } = {};

        for (const tag in tagUsage) {
            const counts = tagUsage[tag];
            const relevantCounts = counts.filter((item) => {
                const ticketDate = new Date(item.createdAt);
                const diffDays = Math.floor((now.getTime() - ticketDate.getTime()) / (1000 * 3600 * 24));
                return diffDays <= days;
            });

            if (relevantCounts.length > 0) {
                const tagTotal = relevantCounts.reduce((acc, item) => acc + item.count, 0);
                filteredTags[tag] = tagTotal;
            }
        }

        return calculatePercentages(filteredTags, totalCount);
    };

    const calculatePercentages = (tagCounts: { [key: string]: number }, totalCount: number) => {
        return Object.entries(tagCounts)
            .map(([tag, count]) => ({
                tag,
                count,
                percentage: totalCount > 0 ? ((count / totalCount) * 100).toFixed(2) : '0.00',
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 10); // Top 10
    };

    const calculateIncludedTagCounts = (includedTags: string[], filteredTickets: Ticket[], days?: number) => {
        const counts: { [key: string]: number } = {};
        const now = new Date();

        filteredTickets.forEach((ticket) => {
            const ticketDate = new Date(ticket.createdAt);
            const diffDays = Math.floor((now.getTime() - ticketDate.getTime()) / (1000 * 3600 * 24));
        
            // If days is specified, only include tickets from that period
            if (days === undefined || diffDays <= days) {
                ticket.tags.forEach((tag) => {
                    // Only count tags that are in includedTags and not in excludeTags
                    if (includedTags.includes(tag) && !filters.excludeTags.includes(tag)) {
                        counts[tag] = (counts[tag] || 0) + 1;
                    }
                });
            }
        });

        return counts;
    };

    const calculateTagCounts = () => {
        const tagUsage: { [key: string]: { count: number; createdAt: string }[] } = {};
        const filteredTickets = tickets.filter((ticket) => {
            // Check if ticket matches all positive filters
            const matchesFilters = (Object.keys(filters) as Array<keyof typeof filters>).every((key) => {
                const filterValues = filters[key];
        
                // Skip excludeTags here, handle separately below
                if (key === 'excludeTags') return true;
        
                return filterValues.length === 0 || filterValues.some((filter) => ticket.tags.includes(filter));
            });
        
            // Check that ticket does not contain any excludeTags
            const matchesExcludeTags = filters.excludeTags.length === 0 || !filters.excludeTags.some((excludeTag) => ticket.tags.includes(excludeTag));
        
            // Return true only if ticket meets all filter conditions and does not contain excludeTags
            return matchesFilters && matchesExcludeTags;
        });

        filteredTickets.forEach((ticket) => {
            ticket.tags.forEach((tag) => {
                if (!excludedTags.includes(tag)) {
                    if (!tagUsage[tag]) tagUsage[tag] = [];
                    tagUsage[tag].push({ count: 1, createdAt: ticket.createdAt });
                }
            });
        });

        const totalCountYesterday = filteredTickets.filter((ticket) => {
            const diffDays = Math.floor((new Date().getTime() - new Date(ticket.createdAt).getTime()) / (1000 * 3600 * 24));
            return diffDays <= 1;
        }).length;

        const totalCountLast7Days = filteredTickets.filter((ticket) => {
            const diffDays = Math.floor((new Date().getTime() - new Date(ticket.createdAt).getTime()) / (1000 * 3600 * 24));
            return diffDays <= 7;
        }).length;

        const totalCountAllTime = filteredTickets.length;

        setTagCounts({
            yesterday: calculateTagsForPeriod(tagUsage, 1, totalCountYesterday),
            last7Days: calculateTagsForPeriod(tagUsage, 7, totalCountLast7Days),
            allTime: calculateTagsForPeriod(tagUsage, 15, totalCountAllTime),
        });

        setTotalCounts({
            yesterday: totalCountYesterday,
            last7Days: totalCountLast7Days,
            allTime: totalCountAllTime,
        });
    };

    useEffect(() => {
        calculateTagCounts();
    }, [tickets, filters, excludedTags]);

    const uniqueTags = Array.from(new Set(tickets.flatMap((ticket) => ticket.tags)))
        .filter((tag) => !/^\d+\.\d+$/.test(tag))
        .map((tag) => ({ value: tag, label: tag }))
        .sort((a, b) => {
            const tagA = tagCounts.yesterday?.find((t) => t.tag === a.value);
            const tagB = tagCounts.yesterday?.find((t) => t.tag === b.value);
            return (tagB?.count || 0) - (tagA?.count || 0);
        });

    const handleTagClick = (tag: string) => {
        const newExcludedTags = [...excludedTags, tag];
        onExcludeChange(newExcludedTags.map((tag) => ({ value: tag, label: tag })), { action: 'select-option', option: { value: tag, label: tag } });
    };

    const handleIncludeChange = (selected: MultiValue<{ value: string; label: string }>) => {
        const newIncludedTags = selected.map((tag) => tag.value);
        setIncludedTags(newIncludedTags);
    };

    const handleIncludedTagClick = (tag: string) => {
        const newIncludedTags = includedTags.filter((includedTag) => includedTag !== tag);
        setIncludedTags(newIncludedTags);
    };

    return (
        <div className='tag-container'>
            <h1 style={{ marginBottom: "10px" }}>Tag View</h1>
            <div className="exclude-tags-container" style={{ marginBottom: "10px" }}>
                <label style={{ fontWeight: "bold" }}>Exclude Tags:</label>
                <Select
                    isMulti
                    options={uniqueTags}
                    onChange={onExcludeChange}
                    value={excludedTags.map((tag) => ({ value: tag, label: tag }))}
                />
            </div>
            <div className="include-tags-container" style={{ marginBottom: "10px" }}>
                <label style={{ fontWeight: "bold" }}>Include Tags:</label>
                <Select
                    isMulti
                    options={uniqueTags}
                    onChange={handleIncludeChange}
                    value={includedTags.map((tag) => ({ value: tag, label: tag }))}
                />
            </div>
            <div className="tag-usage-row">
                {(['yesterday', 'last7Days', 'allTime'] as const).map((period) => (
                    <div key={period} className="tag-usage-period">
                        <h2>{period === 'yesterday' ? 'Yesterday' : period === 'last7Days' ? 'Last 7 Days' : 'Last 15 Days'}</h2>
                        <table className="tag-table">
                            <thead>
                                <tr>
                                    <th>Tag</th>
                                    <th>Count</th>
                                    <th>Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tagCounts[period]?.map(({ tag, count, percentage }) => (
                                    <tr key={tag}>
                                        <td>
                                            <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleTagClick(tag)}>
                                                {tag}
                                            </span>
                                        </td>
                                        <td>{count}</td>
                                        <td>{percentage}%</td>
                                    </tr>
                                ))}
                                {includedTags.map((tag) => {
                                    const filteredTickets = tickets.filter((ticket) => {
                                        // Check if ticket matches all positive filters
                                        const matchesFilters = (Object.keys(filters) as Array<keyof typeof filters>).every((key) => {
                                            const filterValues = filters[key];
                                    
                                            // Skip excludeTags here, handle it separately below
                                            if (key === 'excludeTags') return true;
                                    
                                            return filterValues.length === 0 || filterValues.some((filter) => ticket.tags.includes(filter));
                                        });
                                    
                                        // Check that ticket does not contain any excludeTags
                                        const matchesExcludeTags = filters.excludeTags.length === 0 || !filters.excludeTags.some((excludeTag) => ticket.tags.includes(excludeTag));
                                    
                                        // Return true only if ticket meets all filter conditions and does not contain excludeTags
                                        return matchesFilters && matchesExcludeTags;
                                    });
                                    const count = calculateIncludedTagCounts([tag], filteredTickets, period === 'yesterday' ? 1 : period === 'last7Days' ? 7 : undefined)[tag] || 0; // Get the count for the included tag based on the period
                                    const percentage = totalCounts[period] > 0 ? ((count / totalCounts[period]) * 100).toFixed(2) : '0.00'; // Calculate the percentage
                                    return (
                                        <tr key={tag} style={{backgroundColor:"lightgray"}}>
                                            <td>
                                                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleIncludedTagClick(tag)}>
                                                    {tag}
                                                </span>
                                            </td>
                                            <td>{count}</td>
                                            <td>{percentage}%</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="filtered-count">
                            <strong>Total Count: {totalCounts[period]}</strong>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TagUsage;