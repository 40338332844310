// LoadingScreen.tsx
import React, { useState } from 'react';
import '../styles/LoadingScreen.css';
import AnimatedCountdown from './AnimatedCountdown';

interface LoadingScreenProps {
    onSubmit: (days: number) => void;
    showCountdown: boolean;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ onSubmit, showCountdown }) => {
    const [days, setDays] = useState('');
    const [timeLimit, setTimeLimit] = useState<number>(20); // Default countdown time

    const handleSubmit = () => {
        const parsedDays = parseInt(days, 10);
        if (!isNaN(parsedDays)) {
            // Calculate time limit: 20 + 5 seconds for every 5-day increment
            const calculatedTimeLimit = 20 + Math.ceil(parsedDays / 5) * 5;
            setTimeLimit(calculatedTimeLimit);
            onSubmit(parsedDays);
        }
    };

    return (
        <div className="loading-screen">
            {showCountdown ? (
                <AnimatedCountdown timeLimit={timeLimit} />
            ) : (
                <div className="days-input">
                    <input
                        type="number"
                        value={days}
                        onChange={(e) => setDays(e.target.value)}
                        placeholder="Enter days"
                        className="days-input-field"
                    />
                    <button onClick={handleSubmit} className="days-submit-button">
                        Submit
                    </button>
                </div>
            )}
        </div>
    );
};

export default LoadingScreen;