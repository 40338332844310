// Zendesk.tsx
// Zendesk.tsx
import React, { useState } from 'react';
import Select, { MultiValue } from 'react-select';
import Ticket from '../models/Ticket';
import TicketStatus from '../components/TicketStatus';
import TagUsage from '../components/TagUsage';
import IssueView from '../components/IssueView';
import LoadingScreen from '../components/LoadingScreen';
import '../styles/zendesk.css';
import axios from 'axios';

const Zendesk: React.FC = () => {
    const [tickets, setTickets] = useState<Ticket[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showCountdown, setShowCountdown] = useState<boolean>(false);
    const [excludedTags, setExcludedTags] = useState<string[]>(['aps-', 'empty_ticket', 'gpt_updated']);
    const [filters, setFilters] = useState({
        game: ['find-differences', 'find-hidden-objects', 'find-master'],
        platform: ['ios', 'android', 'unknown-platform'],
        ticketType: ['review', 'email'],
        version: [] as string[],
        excludeTags: ["empty_ticket"] as string[],
    });

    // Fetch tickets with the specified number of days
    const fetchTickets = async (days: number) => {
        try {
            setLoading(true);
            setShowCountdown(true); // Start countdown after submitting the input
            const response = await axios.get(`https://app-462nclm3ma-uc.a.run.app/fetchTickets?days=${days}`);
            console.log('API Response:', response.data);
            
            // Check if response data is an array and map it
            if (Array.isArray(response.data)) {
                const fetchedTickets: Ticket[] = response.data.map((ticketData: any) => new Ticket(ticketData));
                setTickets(fetchedTickets);
            } else {
                console.error('Response is not an array:', response.data);
            }
        } catch (error) {
            console.error('Error fetching tickets:', error);
        } finally {
            setLoading(false);
        }

        // try {
        //     const ticketJson = require('../models/tickets.json');
        //     const fetchedTickets: Ticket[] = ticketJson.map((ticketData: any) => new Ticket(ticketData));
        //     setTickets(fetchedTickets);
        //     setLoading(false);
        // } catch (error) {
        //     console.error('Error fetching tickets:', error);
        // }
    };

    // Handle days submission from loading screen
    const handleDaysSubmit = (submittedDays: number) => {
        fetchTickets(submittedDays);
    };

    // Exclude tags handler
    const handleExcludeChange = (selected: MultiValue<{ value: string; label: string }>) => {
        setExcludedTags(selected.map((tag) => tag.value));
    };

    // Filter change handler
    const handleFilterChange = (selected: MultiValue<{ value: string; label: string }>, filterKey: keyof typeof filters) => {
        setFilters((prev) => {
            const updatedFilters = { ...prev, [filterKey]: selected.map((option) => option.value) };
    
            if (filterKey === 'excludeTags') {
                const excludedTagValues = selected.map((option) => option.value);
                updatedFilters.excludeTags = excludedTagValues;
            }
    
            return updatedFilters;
        });
    };

    // Filter options
    const gameOptions = [
        { value: 'find-differences', label: 'Find Differences' },
        { value: 'find-hidden-objects', label: 'Find Hidden Objects' },
        { value: 'find-master', label: 'Find Master' },
    ];
    const platformOptions = [
        { value: 'ios', label: 'IOS' },
        { value: 'android', label: 'Android' },
        { value: 'unknown-platform', label: 'Unknown Platform' },
    ];
    const ticketTypeOptions = [
        { value: 'review', label: 'Review' },
        { value: 'email', label: 'Email' },
    ];
    const allTags = Array.from(new Set(tickets.flatMap((ticket) => ticket.tags)));
    const versionOptions = allTags
        .filter((tag) => /^\d+\.\d+$/.test(tag))
        .map((tag) => ({ value: tag, label: tag }))
        .sort((a, b) => b.value.localeCompare(a.value, undefined, { numeric: true }));
    const uniqueTags = Array.from(new Set(tickets.flatMap((ticket) => ticket.tags)))
        .map((tag) => ({ value: tag, label: tag }))
        .sort((a, b) => a.value.localeCompare(b.value));

    return (
        <div className="zendesk-container">
            {loading ? (
                <LoadingScreen onSubmit={handleDaysSubmit} showCountdown={showCountdown} />
            ) : (
                <>
                    <div className='filters-back'>
                        <div className="filters-container">
                            <h1>Filters</h1>
                            <div className="filter-group">
                                <label className='filter-box'>
                                    <strong>Game:</strong>
                                    <Select
                                        isMulti
                                        options={gameOptions}
                                        onChange={(selected) => handleFilterChange(selected, 'game')}
                                        value={gameOptions.filter((option) => filters.game.includes(option.value))}
                                    />
                                </label>
                                <label className='filter-box'>
                                    <strong>Platform:</strong>
                                    <Select
                                        isMulti
                                        options={platformOptions}
                                        onChange={(selected) => handleFilterChange(selected, 'platform')}
                                        value={platformOptions.filter((option) => filters.platform.includes(option.value))}
                                    />
                                </label>
                                <label className='filter-box'>
                                    <strong>Ticket Type:</strong>
                                    <Select
                                        isMulti
                                        options={ticketTypeOptions}
                                        onChange={(selected) => handleFilterChange(selected, 'ticketType')}
                                        value={ticketTypeOptions.filter((option) => filters.ticketType.includes(option.value))}
                                    />
                                </label>
                                <label className='filter-box'>
                                    <strong>Version:</strong>
                                    <Select
                                        isMulti
                                        options={versionOptions}
                                        onChange={(selected) => handleFilterChange(selected, 'version')}
                                        value={versionOptions.filter((option) => filters.version.includes(option.value))}
                                    />
                                </label>
                                <label className='filter-box'>
                                    <strong>Exclude Tags:</strong>
                                    <Select
                                        isMulti
                                        options={uniqueTags}
                                        onChange={(selected) => handleFilterChange(selected, "excludeTags")}
                                        value={uniqueTags.filter((option) => filters.excludeTags.includes(option.value))}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <TicketStatus tickets={tickets} filters={filters} />
                    <TagUsage tickets={tickets} filters={filters} excludedTags={excludedTags} onExcludeChange={handleExcludeChange} />
                    <IssueView tickets={tickets} filters={filters} />
                </>
            )}
        </div>
    );
};

export default Zendesk;