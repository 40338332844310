// src/components/PrivateRoute.tsx

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../services/firebase';
import { onAuthStateChanged, User } from 'firebase/auth';

interface PrivateRouteProps {
    children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null | undefined>(undefined);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user || null);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    if (currentUser === undefined) {
        // Authentication state is still being determined
        return null; // You can show a loading spinner here if desired
    }

    if (!currentUser) {
        // No user is signed in
        return <Navigate to="/login" />;
    }

    // User is signed in
    return children;
};

export default PrivateRoute;